import type { ActionSource } from 'owa-analytics-types';
import { lazyOnMarkAsReadUnread } from 'owa-mail-commands-actions';

/**
 * Set item(s) to read or unread state based on current selected item(s)' read/unread state.
 * This function supports multi-select in mail list.
 *
 * @param isReadValueToSet used to set item's read/unread state, and can be undefined.
 * If undefined, this function will calculate the current read/unread state given current selected items' read/unread state.
 * This is to allow for "toggle" functionality, of wanting to toggle read/unread state without parent caller needing
 * to know current item's read/unread state.
 * @param rowKeysToUse optional array of row keys to use instead of selected row keys
 */
export default function onMarkAsReadUnread(
    actionSource: ActionSource,
    isReadValueToSet?: boolean,
    rowKeysToUse?: string[]
) {
    lazyOnMarkAsReadUnread.importAndExecute(actionSource, isReadValueToSet, rowKeysToUse);
}
